<template>
  <div class="member-tab">
    <h4>多多兔-会员列表</h4>
    <el-table :data="tableData" style="width: 100%" v-loading="loading" element-loading-spinner="el-icon-loading">
      <el-table-column prop="name" label="开卡门店" width="180">
      </el-table-column>
      <el-table-column prop="start_time" label="开卡日期" width="200">
      </el-table-column>
      <el-table-column prop="end_time" label="到期日期" width="200">
      </el-table-column>
      <el-table-column prop="phone" label="联系方式">
      </el-table-column>
      <el-table-column prop="deposit" label="押金">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">退押金</el-button>
          <el-button @click="editClick(scope.row)" type="text" size="small">修改押金</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :page-size="10" layout="prev, pager, next" :total="total" @current-change='chageSize'>
    </el-pagination>
  </div>
</template>


<script>
export default {
  data() {
    return {
      tableData: [],
      limit: 10,
      page: 1,
      total: 0,
      loading: true
    }
  },
  created() {
    this.getmemberList()
  },
  methods: {
    async getmemberList() {
      const res = await this.axios.get(`cashier/user_vip?limit=${this.limit}&page=${this.page}`)
      if (res.code === 200) {
        res.data.list.forEach(el => {
          el.name = el.store.name
          el.phone = el.user.phone
        });
        this.tableData = res.data.list
        this.total = res.data.meta.total
        this.loading = false
        console.log(res)
      }
    },
    handleClick(row) {
      console.log(row)
      this.$confirm('请确认此会员以归还所有玩具, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    editClick(row) {
      this.$prompt('修改押金原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.ModifyAmount(value, row.user_id, row.deposit)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    ModifyAmount(mes, id, deposit) {
      this.$prompt('请输入修改金额', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.editdeposit(value, id, mes, deposit)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入1'
        });
      });
    },
    async editdeposit(value, id, mes, deposit) {
      const res = await this.axios.post('cashier/chang/deposit', {
        user_id: id,
        type: value > deposit ? 1 : 2,
        deposit: value,
        message: mes
      })
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: '修改成功'
        });
        this.getmemberList()
      } else {
        this.$message.error(res.message);
      }
    },
    chageSize(page) {
      console.log(page)
    },

  },
}
</script>

<style lang="less" scoped>
.member-tab {
  padding-top: 15px;
}
h4 {
  font-size: 30px;
  margin-bottom: 10px;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
.el-pagination {
  float: right;
}
</style>